<template>
  <div class="box">
    <el-card class="box-card">
      <div class="underline" style="margin-bottom: 10px">
        <p class="title">{{ form.name }}</p>
      </div>
      <div class="underline">
        <span class="underline-2">总分：{{ form.totalPoints }}</span>
        <span class="underline-2">时长：{{ form.suggestTime + "分钟" }}</span>
      </div>
      <el-divider></el-divider>
      <div class="underline">
        <span
          :class="
            item['el-green']
              ? item['error']
                ? 'el-green error'
                : 'el-green'
              : item['error']
              ? 'el-tag'
              : 'el-tag'
          "
          v-for="(item, index) in nav_span"
          :key="index"
          @click="click_nav_span(item, index)"
          >{{ item.index }}</span
        >
      </div>
      <el-divider></el-divider>
      <div
        class="underline"
        style="display: flex; flex-direction: column; text-align: center"
      >
        <p>剩余时间</p>
        <span class="time">{{ form.surplus_time || "00:00" }}</span>
      </div>
      <el-divider></el-divider>
      <div v-if="jurisdiction ? false : true" class="ScoringDetails">
        <p>得分：{{ count }}分</p>
        <p>答对：{{ userTopicRight }}道</p>
        <p>答错：{{ userTopicWrong }}道</p>
      </div>
      <div style="margin-top: 20px; position: relative">
        <el-button
          type="primary"
          class="class_submit"
          :disabled="jurisdiction ? false : true"
          @click="submit"
          >提交按钮</el-button
        >
      </div>
    </el-card>
    <el-scrollbar style="height: 100%">
      <el-card class="box-card-right">
        <div class="div_title">
          <p class="title">{{ form.name }}</p>
        </div>
        <subjectList
          :form="form"
          @alterColout="alterColout"
          :jurisdiction="jurisdiction"
        ></subjectList>
      </el-card>
    </el-scrollbar>
    <!-- 查看完成情况 -->
    <el-dialog
      title="考试结果"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :show-close="false"
    >
      <span class="questionsMsgSpan">试卷得分：{{ count }}分</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="signOutNext">退 出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import subjectList from "./components/subjectList.vue";
import {
  examMessage,
  studentExamSubmit,
  studentExamMessage,
} from "../../api/api";
export default {
  data() {
    return {
      form: {
        name: "",
        totalPoints: 0,
        suggestTime: 0,
        surplus_time: 0, //剩余时间
        option: [],
        radio: 6,
      },
      nav_span: [],
      caseId: "28", //变式id
      appIdUser: null, //当前用户
      time: 0, //剩余秒数
      listBoxState: true, //点击导航栏时，暂时停止监听页面滚动
      navgatorIndex: 0,
      centerDialogVisible: false,
      count: 0, //试卷得分
      userTopicRight: 0, //答对题数
      userTopicWrong: 0, //答错
      jurisdiction: false,//试卷做题权限
    };
  },
  components: {
    subjectList,
  },

  mounted() {
    this.analysis();
    let setTimeoutId;
    window.addEventListener(
      "scroll",
      () => {
        // 页面滚动停止100毫秒后才会执行下面的函数。
        clearTimeout(setTimeoutId);
        setTimeoutId = setTimeout(() => {
          this.scrollToTop();
        }, 100);
      },
      true
    );
    this.init(); //初始化
  },
  watch: {
    time(val, old) {
      if (val !== old) {
        // 时间结束自动提交,并消除定时器
        if (val == 0) {
          this.submit(1);
          clearInterval(this.$store.state.TimeId);
          let key =
            this.$route.query.caseId +
            "~" +
            this.appIdUser +
            "~" +
            this.$route.query.activeId;
          var arr = JSON.parse(localStorage.getItem("test_time"));
          arr.forEach((item, index) => {
            Object.keys(item).forEach((item) => {
              if (item == key) {
                arr.splice(index, 1);
                localStorage.setItem("test_time", JSON.stringify(arr));
                return;
              }
            });
          });
        }
      }
    },
  },
  methods: {
    init() {
      this.caseId = this.$route.query.caseId;
      if (this.$route.query.isComplete == 1) {
        //查看
        studentExamMessage({
          id: this.$route.query.caseId,
          userId: this.appIdUser,
          activiyId: this.$route.query.activeId,
        }).then((res) => {
           this.$store.commit("setBrowserRouter", false);
          this.disposeData(res);
          this.count = res.data[0].userScore;
          this.userTopicRight = res.data[0].userTopicRight;
          this.userTopicWrong = res.data[0].userTopicWrong;
          this.jurisdiction = false;
          this.alterColout();
        });
      } else {
        examMessage({
          id: this.$route.query.caseId,
          activityId: this.$route.query.activeId,
        }).then((res) => {
          if (res.message == "请求成功") {
            this.disposeData(res);
            // 时间计时器
            if (this.jurisdiction) {
              let timeId = setInterval(() => {
                this.answerTime();
                this.$store.commit("timeRefresh", timeId);
              }, 1000);
          
            }
          }
        });
      }
    },
    //处理数据
    disposeData(res) {
      console.log(res.data[0]);
      this.form.name = res.data[0].name;
      this.form.suggestTime = res.data[0].suggestTime;
      this.form.totalPoints = res.data[0].score;
      this.form.option = res.data[0].headlineMessage;
      this.answerTime();
      this.form.option.map((item, index) => {
        item["option"] = [];
        item["topicMessage"].map((ite) => {
          var obj = ite[0];
          obj["examTopicAnswerEntityList"] = ite[1][0];
          item["option"].push(obj);
        });
      });
      var num = 0;
      this.form.option.map((item, index) => {
        item["option"].map((opt, inde) => {
          if (opt.topicType == 3) {
            if (opt.rightAnswers.length > 0) {
              opt.rightAnswers = opt.rightAnswers.split(",");
            } else {
              opt.rightAnswers = [];
            }
          }
          this.nav_span.push({
            index: ++num,
            id: opt.id,
            "el-green": false,
            error: false,
          });
        });
      });
    },
    //退出
    signOutNext() {
       this.$store.commit("setBrowserRouter", false);
      this.$router.push({
        path: "/activityDetail",
        query: this.$route.query,
      });
    },
    // 提交答案
    submit(type) {
      var obj = {
        userId: Number(this.appIdUser),
        examId: Number(this.$route.query.caseId),
        consumingTime: parseInt((this.form.suggestTime * 60 - this.time) / 60), //分钟*60变成秒-剩余秒得到用时秒*60变成分钟取整
        activityId: Number(this.$route.query.activeId),
      };
      var arr = [];
      this.form.option.map((item, index) => {
        item["option"].map((opt, inde) => {
          if (opt.topicType == 3) {
            if (opt.rightAnswers instanceof Array) {
              opt.rightAnswers = opt.rightAnswers.join(",");
            }
          }
          arr.push({ topicId: opt.id, answer: opt.rightAnswers });
        });
      });
      obj["topicMessage"] = arr;
      if (type == 1) {
        studentExamSubmit(obj).then((res) => {
          this.count = res.data.count;
          this.centerDialogVisible = true;
          this.$message({
            message: "时间已到，自动交卷",
            type: "success",
          });
        });
      } else {
        this.$confirm("你确定提交答案吗？ 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            studentExamSubmit(obj).then((res) => {
              this.count = res.data.count;
              this.$message({
                message: "您已成功提交答案",
                type: "success",
              });
              this.centerDialogVisible = true;
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消提交",
            });
          });
      }
    },
    //判断当前题是否做完
    alterColout() {
      this.nav_span.map((ite, i) => {
        this.form.option.map((item, index) => {
          item["option"].map((opt, opt_inde) => {
            if (ite.id == opt.id) {
              if (!(opt.rightAnswers == "" || opt.rightAnswers == [])) {
                ite["el-green"] = true;
                if (opt.topicRoW == 2) {
                  ite["error"] = true;
                }
              } else {
                if (opt.topicRoW == 2) {
                  ite["error"] = true;
                }
                ite["el-green"] = false;
              }
            }
          });
        });
      });
    },

  
    click_nav_span(item, index) {
      this.navgatorIndex = item.id;
      this.$el.querySelector(`#id${item.id}`).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
      this.listBoxState = false;
      let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState = true;
      }, 200);
    },
    scrollToTop() {
      // 获取视窗高度
      var domHight = document.body.offsetHeight;
      // dom滚动位置
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (this.listBoxState) {
        //作用是点击导航栏时，延迟这里执行。
        this.nav_span.map((v, i) => {
          // 获取监听元素距离视窗顶部距离
          var offsetTop = document.getElementById(`id${v.id}`).offsetTop;
          // 获取监听元素本身高度
          var scrollHeight = document.getElementById(`id${v.id}`).scrollHeight;
          // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
          // 则表示页面已经滚动到可视区了。
          if (scrollTop >= offsetTop && scrollTop <= offsetTop + scrollHeight) {
            // 导航栏背景色选中
            this.navgatorIndex = v.id;
          }
        });
      }
    },
    // 解析appIds
    analysis() {
      var urlAppId = window.location.href.split("=")[2].split("#")[0];
      let appIds = JSON.parse(localStorage.getItem("appIds"));
      if (appIds) {
        let target = appIds.find((item) => {
          return item.appId === urlAppId;
        });
        this.appIdUser = target.userId;
      }
      if (this.utils.identity("试卷做题")) {
        this.jurisdiction = true;
      }else{
         this.$store.commit("setBrowserRouter", false);
      }
    },
    // 本地保存时间
    answerTime(val) {
      let arr = [];
      let key =
        this.$route.query.caseId +
        "~" +
        this.appIdUser +
        "~" +
        this.$route.query.activeId;
      // 如果本地没有
      if (!localStorage.test_time) {
        this.time = this.form.suggestTime * 60;
        this.time = this.time - 1;
        let surplus = null;
        surplus = this.duration.s_to_ms(this.time);
        this.form.surplus_time = surplus;
        let obj = {};
        obj[key] = this.form.surplus_time;
        arr.push(obj);
      } else {
        // 如果本地有
        arr = JSON.parse(localStorage.getItem("test_time"));
        let isKey = null;
        let isValue = null;
        arr.forEach((item, index) => {
          Object.keys(item).forEach((item) => {
            if (item == key) {
              isKey = arr[index];
              isValue = arr[index][item];
            }
          });
        });
        // 是当前的题目
        if (isKey) {
          this.form.surplus_time = isValue;
          this.time = this.duration.ms_to_s(isValue);
          this.time = this.time - 1;
          let surplus = null;
          surplus = this.duration.s_to_ms(this.time);
          this.form.surplus_time = surplus;
          isKey[key] = this.form.surplus_time;
        } else {
          this.time = this.form.suggestTime * 60;
          this.time = this.time - 1;
          let surplus = null;
          surplus = this.duration.s_to_ms(this.time);
          this.form.surplus_time = surplus;
          let obj = {};
          obj[key] = this.form.surplus_time;
          arr.push(obj);
        }
      }
      localStorage.setItem("test_time", JSON.stringify(arr));
    },
  },
};
</script>

<style lang='less' scoped>
@import "./style/index.less";
.questionsMsgSpan {
  display: block;
  margin: 0 auto;
  text-align: center;
}
</style>