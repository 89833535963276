<!-- 小测试答题题目列表 -->
<template>
  <div style="height: 100%">
    <div v-for="(item, i) in form.option" :key="i">
      <div class="exam-paper-item-box">
        <p style="font-size: 18px">{{ ++i }}.{{ item.headlineName }}</p>
        <div
          class="exam-paper-item-box-contain"
          style="margin-left: 25px"
          v-for="(ite, ind) in item.option"
          :key="ind"
          :id="'id' + ite.id"
        >
          <div class="itemOrder">{{ ++ind }}.</div>
          <div class="title-contain">
            <div class="q-title">
              <span v-html="ite.topicName" class="content"> </span>
            </div>
            <div class="q-content">
              <template>
                <el-radio-group
                  :disabled="jurisdiction ? false : true"
                  v-model="ite.rightAnswers"
                  v-if="ite.topicType == 2 || ite.topicType == 4"
                  @change="ChangeRadio"
                >
                  <el-radio
                    :label="opt.serial"
                    v-for="(opt, index) in ite.examTopicAnswerEntityList"
                    :key="index"
                    ><span v-if="ite.topicType == 2" class="option-A">{{ opt.serial }}</span>
                    <span v-html="opt.content" class="content"> </span>
                  </el-radio>
                </el-radio-group>
                <el-checkbox-group
                  :disabled="jurisdiction ? false : true"
                  v-model="ite.rightAnswers"
                  v-if="ite.topicType == 3"
                  @change="ChangeRadio"
                >
                  <el-checkbox
                  class="option"
                    :label="option_item.serial"
                    v-for="(
                      option_item, option_index
                    ) in ite.examTopicAnswerEntityList"
                    :key="option_index"
                    ><span class="option-A">{{ option_item.serial }}</span>
                    <span v-html="option_item.content" class="content"> </span>
                  </el-checkbox>
                </el-checkbox-group>
              </template>
            </div>
           <!-- <p class="particulars" v-if="jurisdiction ? false : true">标答:{{ ite.answers }}</p> -->
            <div v-if="ite.topicRoW == 2 || ite.topicRoW == 1">
              <p class="particulars">标答:{{ ite.answers }}</p>
              <p class="particulars">
                结果:
                <span v-if="ite.topicRoW == 2" class="el-tag error">错误</span
                ><span class="el-tag correct" v-if="ite.topicRoW == 1"
                  >正确</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
    },
    jurisdiction: {
      type: Boolean,
    },
  },
  data() {
    return {
      checkList: [],
    };
  },

  components: {},

  methods: {
    ChangeRadio() {
      this.$emit("alterColout");
    },
  },
};
</script>
<style lang='less' scoped>
@import "../style/index.less";
.content {
  /deep/p {
    display: inline;
  }
}
</style>
